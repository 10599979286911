import React from "react";
import { RiMenu3Line, RiCloseLine } from "react-icons/ri";
import PropTypes from "prop-types";

import tw, { css } from "twin.macro";

import { ButtonText } from "@components/shared";

const MenuText = ButtonText.withComponent("span");

const Hamburger = ({ isMenuOpen, ...props }) => (
  <button
    css={[
      css`
        ${tw`
          fixed top-6 right-menu z-50
          p-3
          rounded-full
          focus:outline-none
        `}

        animation: slideInLeft 75ms ease-in forwards;
      `,
      !isMenuOpen && tw`bg-white shadow-lg`,
    ]}
    {...props}
  >
    {!isMenuOpen && (
      <MenuText tw="to-xs:hidden absolute top-1/2 right-full z-50 mr-2 transform -translate-y-1/2">
        Menu
      </MenuText>
    )}
    {isMenuOpen ? (
      <RiCloseLine tw="text-2xl text-primary" />
    ) : (
      <RiMenu3Line tw="text-2xl" />
    )}
  </button>
);

Hamburger.propTypes = {
  isMenuOpen: PropTypes.bool.isRequired,
};

export default Hamburger;
