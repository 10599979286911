import React from "react";
import { FiChevronDown } from "react-icons/fi";
import PropTypes from "prop-types";

import tw, { css } from "twin.macro";

import Option from "./Option";

const Dropdown = ({ children, to, text, hideDropdown }) => (
  <div
    css={[
      !hideDropdown && tw`to-sm:mb-2 mb-4`,
      css`
        ${tw`relative inline-flex flex-col`}

        &:hover .list {
          ${tw`opacity-100 scale-100 pointer-events-auto`}
        }
      `,
    ]}
  >
    <div className="link" tw="flex items-center justify-center">
      <Option to={to} text={text} tw="m-0!" />
      <FiChevronDown tw="text-primary" />
    </div>
    <div
      className="list"
      css={[
        css`
          ${tw`
          flex flex-col
          relative z-50
          rounded-b-bottom
          whitespace-no-wrap
          transition-all duration-75 ease-in
        `}

          & a {
            ${tw`mb-1! hover:underline text-center lowercase`}
          }
        `,
        hideDropdown &&
          css`
            ${tw`
            absolute top-full left-1/2
            p-8
            bg-white
            border-t-4 border-primary
            shadow-xl
            transform -translate-x-1/2 scale-75 origin-top
            opacity-0
            pointer-events-none
          `}

            &:before {
              content: "";
              ${tw`absolute top-0 left-1/2 z-50 block text-center w-2 h-2 border-t-8 border-primary transform -translate-x-1/2`}
              border-left: 8px solid transparent;
              border-right: 8px solid transparent;
            }
          `,
      ]}
    >
      {children}
    </div>
  </div>
);

Dropdown.propTypes = {
  children: PropTypes.node.isRequired,
  text: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  hideDropdown: PropTypes.bool.isRequired,
};

export default Dropdown;
