import React from "react";
import PropTypes from "prop-types";

import { Link } from "gatsby";

import tw, { css } from "twin.macro";

import { ButtonText } from "@components/shared";

const OptionText = ButtonText.withComponent(Link);

const Option = ({ to, text, ...props }) => (
  <OptionText
    to={to}
    activeClassName="active"
    css={[
      tw`
        inline-flex items-center
        font-bold
        hover:text-typo-light
        transition-colors duration-100 ease-in
      `,
      css`
        &.active {
          ${tw`relative text-primary`}

          &:before {
            content: "";
            ${tw`
              absolute top-1/2 left-1/2 -z-1
              block
              w-6 h-6
              rounded-full
              bg-primary-pale
              transform -translate-x-1/2 -translate-y-1/2
            `}
          }
        }
      `,
    ]}
    {...props}
  >
    {text}
  </OptionText>
);

Option.propTypes = {
  text: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
};

export default Option;
