import React, { useState } from "react";
import { BsCheckBox, BsInfoCircle } from "react-icons/bs";
import { MdErrorOutline } from "react-icons/md";
import PropTypes from "prop-types";

import addToMailchimp from "gatsby-plugin-mailchimp";

import tw from "twin.macro";

import { Heading, Button, Highlight } from "@components/shared";

const Submit = Button.withComponent("button");

const Newsletter = ({ newsletter }) => {
  const [email, setEmail] = useState("");
  const [isError, setIsError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const { title, button, placeholder } = newsletter;

  const handleSubmit = async (e) => {
    e.preventDefault();
    let { result } = await addToMailchimp(email);

    if (result === "success") {
      setIsSuccess(true);
    } else {
      setIsError(true);
    }
  };

  return (
    <div tw="relative">
      {!isSuccess ? (
        <>
          <Heading as="h3" dangerouslySetInnerHTML={{ __html: title }} />
          <form
            onSubmit={handleSubmit}
            tw="flex to-lg:flex-col justify-center items-center"
          >
            {isError && (
              <Highlight tw="lg:absolute lg:top-full lg:left-1/2 lg:transform lg:-translate-x-1/2 lg:translate-y-1/2 w-2/3 text-primary-light mb-4">
                <MdErrorOutline tw="inline-block mr-1" />
                Podany adres e-mail jest niepoprawny lub znajduje się na naszej
                liście
              </Highlight>
            )}
            <input
              type="text"
              placeholder={placeholder}
              title="email"
              onChange={(e) => setEmail(e.target.value)}
              css={[
                tw`px-4 py-2 rounded-t-top rounded-b-bottom to-lg:mb-4 lg:mr-4 w-full sm:w-1/2 xl:w-1/4 to-lg:text-center text-typo truncate placeholder-typo`,
                isError &&
                  tw`border-4 border-primary-light placeholder-primary`,
              ]}
            />
            <Submit as="button" type="submit">
              {button}
            </Submit>
          </form>
        </>
      ) : (
        <p tw="text-primary md:w-2/3 mx-auto tracking-wider leading-8">
          <BsCheckBox tw="block mb-5 text-6xl mx-auto text-primary-light" />
          <span tw="block font-bold mb-2">
            Dziękujemy za dołączenie do naszej listy subskrybentów!
          </span>
          <BsInfoCircle tw="inline-block mr-2" />
          Aby zacząć otrzymywać regularne wiadomości, prosimy o potwierdzenie
          subskypcji klikając odpowiedni przycisk w wysłanym przez nas mailu.
        </p>
      )}
    </div>
  );
};

Newsletter.propTypes = {
  newsletter: PropTypes.shape({
    title: PropTypes.string.isRequired,
    placeholder: PropTypes.string.isRequired,
    button: PropTypes.string.isRequired,
  }).isRequired,
};

export default Newsletter;
