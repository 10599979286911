import React from "react";
import { FaFacebookSquare, FaInstagram } from "react-icons/fa";

import "twin.macro";

import { SocialIcon } from "@components/shared";

const SocialIcons = ({ ...props }) => (
  <div tw="items-center text-primary-light" {...props}>
    <SocialIcon
      icon={FaFacebookSquare}
      href="https://www.facebook.com/Innyswiatkulinarnieinaczej/"
    />
    <SocialIcon
      icon={FaInstagram}
      href="https://www.instagram.com/innyswiatkulinarnieinaczej/"
    />
  </div>
);

export default SocialIcons;
