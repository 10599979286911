import React from "react";
import { BsFileText } from "react-icons/bs";
import { MdEvent } from "react-icons/md";
import PropTypes from "prop-types";

import tw from "twin.macro";

import { Button, ButtonText } from "@components/shared";

const BottomOption = ({ text, icon: Icon, ...props }) => (
  <ButtonText
    tw="flex flex-col items-center text-tiny w-24 tracking-widest"
    {...props}
  >
    <Icon tw="ml-0! mb-1 text-2xl!" />
    {text}
  </ButtonText>
);

const BottomNav = ({ isMenuOpen }) => (
  <div
    css={[
      tw`
        lg:hidden
        fixed bottom-0 left-0 z-40
        flex justify-around items-center
        w-full
        p-2
        bg-white text-primary
        shadow-lg-reverse
        opacity-0
        transform translate-y-full origin-bottom
        transition-all duration-150 ease-in-out
      `,
      !isMenuOpen && tw`translate-y-0 opacity-100`,
    ]}
  >
    <BottomOption to="/oferta" text="Oferta" icon={BsFileText} />
    <Button to="/kontakt">Rezerwuj</Button>
    <BottomOption to="/wydarzenia" text="Wydarzenia" icon={MdEvent} />
  </div>
);

BottomNav.propTypes = {
  isMenuOpen: PropTypes.bool.isRequired,
};

BottomOption.propTypes = {
  text: PropTypes.string.isRequired,
  icon: PropTypes.func.isRequired,
};

export default BottomNav;
