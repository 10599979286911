import React, { useState, useRef, useEffect } from "react";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";

import "twin.macro";

import { Logo } from "@components";

import { useWindowWidth } from "@helpers";

import Navbar from "./Navbar";
import Hamburger from "./Hamburger";
import HiddenNavbar from "./HiddenNavbar";
import BottomNav from "./BottomNav";

const Header = () => {
  const windowWidth = useWindowWidth();

  const isMobile = windowWidth < 1024;

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isHeaderVisible, setIsHeaderVisible] = useState(!isMobile);

  const header = useRef(0);

  useScrollPosition(
    () => {
      setIsMenuOpen(false);

      const { bottom } = header.current.getBoundingClientRect();
      setIsHeaderVisible(!isMobile ? bottom + 20 > 0 : false);
    },
    ...Array(3),
    50
  );

  return (
    <header
      tw="relative z-30 flex items-center justify-between w-menu mx-auto pt-5"
      ref={header}
    >
      <Logo />
      <Navbar tw="to-lg:hidden justify-between to-xl:w-2/3 xl:w-3/5 focus:outline-none" />
      {(isMobile || !isHeaderVisible) && (
        <Hamburger
          isMenuOpen={isMenuOpen}
          onClick={() => setIsMenuOpen(!isMenuOpen)}
        />
      )}
      <HiddenNavbar
        isMenuOpen={isMenuOpen}
        aria-hidden={!isMobile ? "true" : undefined}
      />
      <BottomNav isMenuOpen={isMenuOpen} />
    </header>
  );
};

export default Header;
