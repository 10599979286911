import React from "react";
import PropTypes from "prop-types";

import { useLocation } from "@reach/router";

import tw from "twin.macro";

import { GatsbySeo } from "gatsby-plugin-next-seo";

import { useSeo } from "@queries";

import { Container } from "@components/shared";

import Header from "./Header";
import Footer from "./Footer";

const Layout = ({ children, showNewsletter, ...props }) => {
  const { pathname } = useLocation();
  const { title, description, og_image } = useSeo();

  return (
    <div tw="text-typo">
      <GatsbySeo
        titleTemplate={pathname !== "/" && "%s • kulinarnieinaczej.pl"}
        title={props.title || title}
        description={props.description}
        openGraph={{
          type: "website",
          url: "https://kulinarnieinaczej.pl/",
          title,
          description,
          images: [
            {
              url: og_image.asset.fluid.src,
              width: 800,
              height: 600,
              alt: og_image.alt,
            },
          ],
        }}
      />

      <Header />
      <Container as="main" css={pathname !== "/" && tw`my-32`}>
        {children}
      </Container>
      <Footer showNewsletter={showNewsletter} />
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  description: PropTypes.string,
  showNewsletter: PropTypes.bool,
};

export default Layout;
