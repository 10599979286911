import React from "react";
import PropTypes from "prop-types";

import tw from "twin.macro";

import { AnchorLink } from "gatsby-plugin-anchor-links";

import shortid from "shortid";

import { urlify } from "@helpers";
import { useOfferList } from "@queries";
import { Button } from "@components/shared";

import Option from "./Option";
import Dropdown from "./Dropdown";
import SocialIcons from "./SocialIcons";

const Navbar = ({ isVertical, ...props }) => {
  const offerList = useOfferList();

  return (
    <nav
      css={[tw`relative flex items-center`, isVertical && tw`flex-col`]}
      {...props}
    >
      <Option to="/" text="Start" />
      <Dropdown to="/oferta" text="Oferta" hideDropdown={!isVertical}>
        {offerList.map((offer) => (
          <AnchorLink
            key={shortid.generate()}
            to={`/oferta#${urlify(offer.title)}`}
            stripHash
          >
            {offer.title}
          </AnchorLink>
        ))}
      </Dropdown>
      <Option to="/wydarzenia" text="Wydarzenia" />
      <Option to="/onas" text="O nas" />
      <Option to="/kontakt" text="Kontakt" />
      <Option to="/galeria" text="Galeria" />
      <Button to="/kontakt">Rezerwuj</Button>
      <SocialIcons css={!isVertical && tw`absolute bottom-full right-0`} />
    </nav>
  );
};

Navbar.propTypes = {
  isVertical: PropTypes.bool,
};

export default Navbar;
