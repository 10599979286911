import React from "react";
import PropTypes from "prop-types";

import tw, { css } from "twin.macro";

import Navbar from "./Navbar";

const HiddenNavbar = ({ isMenuOpen, ...props }) => (
  <Navbar
    css={[
      tw`fixed to-sm:top-0 top-6 to-sm:left-0 to-sm:right-0 to-sm:bottom-0 right-menu z-40 px-24 py-16 sm:rounded-t-top sm:rounded-b-bottom shadow-xl bg-white transform scale-75 opacity-0 origin-top-right transition duration-75 ease-in-out overflow-y-auto max-h-full pointer-events-none`,
      isMenuOpen && tw`scale-100 opacity-100 pointer-events-auto`,
      css`
        & a {
          ${tw`to-sm:mb-2 mb-4`}
        }
      `,
    ]}
    isVertical
    tabIndex="-1"
    {...props}
  />
);

HiddenNavbar.propTypes = {
  isMenuOpen: PropTypes.bool.isRequired,
};

export default HiddenNavbar;
