import React from "react";
import PropTypes from "prop-types";

import tw from "twin.macro";

import { useGlobal } from "@queries";
import { Container, Heading, Socials, Button } from "@components/shared";

import Newsletter from "./Newsletter";

const Footer = ({ showNewsletter }) => {
  const { newsletter, cta } = useGlobal();

  return (
    <Container as="footer" tw="mt-12 to-lg:mb-16">
      <Container.Area css={!showNewsletter && tw`bg-white pt-16 pb-40`}>
        {showNewsletter ? (
          <Newsletter newsletter={newsletter} />
        ) : (
          <>
            <Heading dangerouslySetInnerHTML={{ __html: cta }} />
            <Button to="/kontakt">Rezerwuj</Button>
          </>
        )}
      </Container.Area>
      <div tw="p-8 text-center bg-primary text-white">
        <Socials tw="mb-2" />
        <small tw="text-white font-normal tracking-widest">
          Wszelkie prawa zastrzeżone © {new Date().getFullYear()} -
          kulinarnieinaczej.pl
        </small>
      </div>
    </Container>
  );
};

Footer.propTypes = {
  showNewsletter: PropTypes.bool,
};

export default Footer;
